import React from "react";
import { Section2Image, Section2Character_Bright, Section2ImageMobile } from "assets";
import { Row, Col } from "react-bootstrap";
import useWindowDimensions from "Utility/useWindowDimensions";
import { Breakpoint } from "react-socks";

const Section2 = () => {
  const { vw, vh } = useWindowDimensions();
  return (
    <Row
      className="section2 justify-content-evenly align-items-start align-items-lg-center px-2 px-lg-5"
      style={vw >= 992 ? {
        background: `center / cover no-repeat url(${Section2Image}), linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))`,
        maxWidth: "100vw",
        minHeight: "100vh",
        margin: "0px",
        zIndex: "0",
      } : {
        background: `center / cover no-repeat url(${Section2ImageMobile}), linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))`,
        // maxWidth: "100vw",
        minHeight: "110vh",
        margin: "0px",
        zIndex: "0",
      }}
    >
      {/* <div className="section2"> */}
      {/* <img
          src={Section2Image}
          className="section2-image"
          alt="Section2Background"
        /> */}
      {/* </div> */}
      <Col xs={12} lg={6} xl={5} className="pt-4 pt-lg-auto px-lg-5">

        <h1 className="walsh text-red-white-border mt-3 mt-lg-auto text-spacing-10" ><span className="h2">FEAR,</span><br /> <span className="h3">like you have never seen before</span> </h1>
        <h3 className="walsh mt-4 pb-5 pe-lg-5 mb-lg-5 text-reddish-white paragraph">
          Save the Souls of the loved ones from the evil clutches of Belphegor. Dark Den is a role play game that encompasses aspects of stealth, action, bravado and luck. Loose yourself in woods of a haunted forest, try and catch your breath in the mysterious waters, cross the brutal bridge, enter the predatory cave and climb the vicious peaks. Dark Den is a first person, fast paced task intensive game that gets your adrenaline going. Roll the Dice, for it will change your life!
        </h3>
        {/* <Breakpoint medium down>
          <h1 className="walsh-24-colorful color-red mt-3 mt-lg-auto" style={{fontSize: 24}}><span className="walsh-64" style={{fontSize: 40}}>FEAR,</span><br /> like you have never seen before</h1>
          <h3 className="walsh-24 mt-4 pb-5 pe-5 mb-lg-5 text-reddish-white">
            Save the Souls of the loved ones from the evil clutches of Belphegor. Dark Den is a role play game that encompasses aspects of stealth, action, bravado and luck. Loose yourself in woods of a haunted forest, try and catch your breath in the mysterious waters, cross the brutal bridge, enter the predatory cave and climb the vicious peaks. Dark Den is a first person, fast paced task intensive game that gets your adrenaline going. Roll the Dice, for it will change your life!
          </h3>
        </Breakpoint> */}
      </Col>
      <Col
        xs={12}
        lg={6}
        xl={7}
        className="d-flex justify-content-end align-items-end"
      >
        {/* <img
          src={Section2Character_Bright}
          className="section2-character-image"
          alt="Section2Character"
        /> */}
      </Col>
    </Row>
  );
};

export default Section2;
