import logo from "./logo.svg";
import "./App.css";
import "./AppResponsive.scss";
import Routers from "./Routers";
import { BreakpointProvider } from "react-socks";
import { setDefaultBreakpoints } from "react-socks";

const App = () => {
  setDefaultBreakpoints([
    
    { xxsmall: 0 }, // all mobile devices
    { xsmall: 360 }, // all mobile devices
    { small: 576 }, // mobile devices (not sure which one's this big)
    { medium: 768 }, // ipad, ipad pro, ipad mini, etc
    { large: 992 }, // smaller laptops
    { xlarge: 1366 }, // laptops and desktops
    { xxlarge: 1900 }, // laptops and desktops
  ]);
  
  return (
    <BreakpointProvider>
      <Routers />
    </BreakpointProvider>
  );
};

export default App;
