import React from "react";
import { Section6Image, DarkDenNowOnOculus, DarkDenNowOnOculusMobile, NavBarButton, Section6ImageBg, Section6ImageBgMobile } from "assets";
import { Row, Col, Button } from "react-bootstrap";
import useWindowDimensions from "Utility/useWindowDimensions";
import { Breakpoint } from "react-socks";

const Section6 = () => {
  const { vw, vh } = useWindowDimensions();
  return (
    <>
      <Row
        className="d-flex justify-content-center align-items-center align-items-md-center align-items-lg-center text-center mb-lg-5 p-0 mx-0"
        style={
          vw <= 576
            ? {
              background: `top / cover no-repeat url(${Section6ImageBgMobile}) border-box, rgba(255,255,255,0)`,
              marginTop: "-20%",
              position: "relative",
              zIndex: "7",
              width: "100vw",
              minHeight: "100vh",
              marginRight: "0px",
              marginLeft: "0px",
            }
            : vw <= 992
              ? {
                background: `top / cover no-repeat url(${Section6ImageBg}) border-box, rgba(255,255,255,0)`,
                marginTop: "-5%",
                minHeight: "100vh",
                position: "relative",
                zIndex: "7",
                width: "100vw"
              }
              : {
                background: `top / cover no-repeat url(${Section6ImageBg}) border-box, rgba(255,255,255,0)`,
                marginTop: "-7%",
                minHeight: "100vh",
                position: "relative",
                zIndex: "7",
                maxWidth: "100vw"
              }
        }
      >
        <Col xs={12} className="d-flex flex-column justify-content-center" style={{ height: "100%" }}>
          <Breakpoint large up>
            <img
              className="mt-5 pt-5"
              src={DarkDenNowOnOculus}
              alt="Dark Den Logo"
            />
          </Breakpoint>
          <Breakpoint medium down>
            <img
              className="mt-5 pt-5"
              src={DarkDenNowOnOculusMobile}
              alt="Dark Den Logo"
            />
          </Breakpoint>
          <h3
            className="walsh mt-4 mt-lg-5 pt-3 h3"
            style={{color: "#999999"}}
          >
            Now On Meta Quest (Oculus)
          </h3>
          <div className="mt-lg-5">
            <div className="d-flex justify-content-center align-items-center">
              <Button
                style={{
                  background: `url(${NavBarButton}) no-repeat`,
                  minWidth: "206px",
                  minHeight: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "24px",
                  border: "none",
                  cursor: "pointer",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "1rem",
                  color: "#4D3A2D"
                }}
                className="walsh text-center"
                href="https://www.oculus.com/experiences/quest/5116975614988301/?utm_source=Website&utm_medium=Buy_Now"
                target="__blank"
              >
                Buy Now
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Section6;
