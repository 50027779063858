import React, { useState } from "react";
import { useSpring, animated, config } from "react-spring";
import NavigationBar from "components/NavigationBar";

import * as easings from "d3-ease";

import {
  Section1Image,
  LandingPageButton,
  LandingPageButtonMobile,
  LandingPageButtonYTLogo,
  TextureSocialIcons,
  facebook,
  twitter,
  instagram,
  youtube,
  discord,
  facebookHover,
  twitterHover,
  discordHover,
  instagramHover,
  youtubeHover,
  // LandingPageVideo,
  DarkDenLogoLandingPage,
  oculus,
  Section1BgImage,
  Section1BgImageMobile,
  ArrowUp,
  FearImage,
  FearImageMobile,
  meta,
  Hand,
  HandMobile,
  FearLine,
  FearLineMobile
} from "assets";
import useWindowDimensions from "Utility/useWindowDimensions";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Breakpoint, useCurrentWidth } from "react-socks";


const Section1 = ({ watchTrailer }) => {
  const { vw, vh } = useWindowDimensions();
  const [state1, setState1] = useState(false);
  const [state2, setState2] = useState(false);
  const [state3, setState3] = useState(false);
  const [state4, setState4] = useState(false);

  const [visible, setVisible] = useState(false);
  const [logoVisible, setLogoVisible] = useState(true);
  const [prevent, setPrevent] = useState(false);
  const [totalScrolled, setTotalScrolled] = useState(false);
  const [reverseAnimation, setReverseAnimation] = useState(false);
  const [cancelAnimation, setCancelAnimation] = useState(false);
  const [stopAnimation, setStopAnimation] = useState(false);
  const width = useCurrentWidth();

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > window.innerHeight * 5.25 || scrolled < window.innerHeight * 1.4) {
      setVisible(false);
    } else {
      setVisible(true);
    }

    setLogoVisible(true);
  };

  window.addEventListener("scroll", toggleVisible);

  const onMouseMove = () => {
    setLogoVisible(true);
  };

  const onMouseClick = () => {
    setLogoVisible(true);
  };

  window.addEventListener("mousemove", onMouseMove);

  window.addEventListener("click", onMouseClick);

  const scaleIn = useSpring({
    from: { transform: "scale(1)", transformOrigin: "top left" },
    to: { transform: "scale(1.2)", transformOrigin: "top left" },
    reset: true,
    // reverse: true,
  });
  const scaleOut = useSpring({
    from: { transform: "scale(1.2)", transformOrigin: "top left" },
    to: { transform: "scale(1)", transformOrigin: "top left" },
    reset: true,
  });

  const imgStyle = useSpring({
    from: { scale: 2 },
    to: { scale: 0.8 },
    cancel: cancelAnimation,
    config: { duration: 2000, easing: easings.easeCubicIn }
  });

  const bgStyle = useSpring({
    from: { background: "linear-gradient(0.03deg, #000000 0.03%, rgba(0, 0, 0, 0.55) 55.5%, rgba(0, 0, 0, 0) 99.97%)" },
    to: { background: "linear-gradient(0.03deg, #000000 0.03%, #000000 30.77%, rgba(0, 0, 0, 0.55) 99.45%, rgba(0, 0, 0, 0) 99.97%)" },
    cancel: cancelAnimation,
    config: { duration: 2000, easing: easings.easeCubicIn },
  });

  const textStyle = useSpring({
    from: { y: width >= 992 ? -0 : -10 },
    to: { y: width >= 992 ? -100 : -10 },
    cancel: cancelAnimation,
    config: { duration: 2000, easing: easings.easeCubicIn }
  });

  const navStyle = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    cancel: cancelAnimation,
    config: { duration: 2000, easing: easings.easeCubicIn }
  });

  return (
    <Container className="p-0 w-100" fluid style={{ minHeight: "100vh" }}>
      <animated.div style={navStyle}>
        <NavigationBar />
      </animated.div>
      <Row
        className="d-flex position-absolute justify-content-evenly align-items-start align-items-lg-center w-100"
        style={vw >= 768 ? {
          background: `right / cover no-repeat url(${Section1BgImage}), linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))`,
          maxWidth: "100vw",
          minHeight: "100vh",
          margin: "0px",
          zIndex: "0",
        } : {
          background: "#000",
          // maxWidth: "100vw",
          minHeight: "100vh",
          margin: "0px",
          zIndex: "0",
        }}
      >
        <Breakpoint medium down className="p-0">
          <div className="d-flex justify-content-center position-relative">
            <div className="d-flex justify-content-center position-absolute w-100 overflow-hidden" style={{ zIndex: -2, minHeight: "100vh" }}>
              <animated.img src={Section1BgImageMobile} style={{ maxHeight: "100vh", marginTop: -150, ...imgStyle }} />
            </div>
            <animated.div className="d-flex justify-content-center position-absolute w-100" style={{ zIndex: 2, minHeight: "100vh", ...bgStyle }}>

            </animated.div>
          </div>
        </Breakpoint>
        <Col lg={8} xs={12} style={{ zIndex: 10, minHeight: "100vh" }}>
          <div className="mx-lg-5 px-lg-5 text-center text-lg-start d-flex flex-column justify-content-lg-center justify-content-end" style={{ height: "100vh" }}>
            <Breakpoint medium down style={{ paddingBottom: "15vh" }}>
              <animated.div style={textStyle}>
                <div className="d-flex justify-content-center align-items-center position-relative">
                  <div className="position-relative d-flex flex-column justify-content-center align-items-center">
                    <Image alt="FEAR" className="position-absolute" style={{ right: -30, top: "20%", zIndex: -1 }} src={HandMobile} />
                    <h2 className="h0_5 walsh text-red-white-border-title" style={{ zIndex: 10 }}>FEAR</h2>
                    <Image alt="FEAR" className="position-absolute" style={{bottom: 0}} src={FearLineMobile}/>
                  </div>
                </div>
                <h1 className="walsh text-red-white-border h4" style={{ paddingTop: "0px" }}>
                  EXPERIENCE FEAR IN DARK DEN
                </h1>
                <p className="walsh text-reddish-white mt-4 h4">
                  A VR Game that combines elements<br /> of luck, stealth, action and combat
                </p>
                <div className="d-flex flex-column align-items-lg-start  align-items-center mt-5">
                  <div>
                    <a
                      className="btn walsh d-flex align-items-center justify-content-center"
                      as="button"
                      style={vw >= 576 ? buttonStyle : buttonStyleMobile}
                      href="https://www.oculus.com/experiences/quest/5116975614988301/?utm_source=Website&utm_medium=Buy_Now"
                      target="__blank"
                    >
                      Buy Now
                    </a>
                    <div className="d-flex justify-content-end position-relative my-2">
                      <Image alt="Arrow" src={ArrowUp} />
                    </div>
                  </div>
                </div>
                <div className="walsh" style={{ color: "#666666" }}>
                  AVAILABLE NOW ON
                  <Image className="px-2" src={meta} alt="Oculus" />
                </div>
              </animated.div>
            </Breakpoint>
            <Breakpoint large up>
              <div>
                <div className="d-flex justify-content-start align-items-center position-relative">
                  <div className="position-relative">
                    <Image alt="Hand" className="position-absolute" style={{ right: -60, top: 0, zIndex: -1 }} src={Hand} />
                    <h2 className="h0_5 walsh text-red-white-border-title" style={{ zIndex: 10 }}>FEAR</h2>
                    <Image alt="Fear" className="position-absolute" style={{bottom: 20}} src={FearLine}/>
                  </div>
                </div>
                <h1 className="walsh text-red-white-border h4" style={{ paddingTop: "0px" }}>
                  EXPERIENCE FEAR IN DARK DEN
                </h1>
              </div>
              <p className="walsh text-reddish-white mt-4 h4">
                A VR Game that combines elements<br /> of luck, stealth, action and combat
              </p>
              <div className="d-flex flex-column align-items-lg-start align-items-center mt-5">
                <div>
                  <a
                    className="btn walsh d-flex align-items-center justify-content-center"
                    style={vw >= 576 ? buttonStyle : buttonStyleMobile}
                    href="https://www.oculus.com/experiences/quest/5116975614988301/?utm_source=Website&utm_medium=Buy_Now"
                    target="__blank"
                  >
                    Buy Now
                  </a>
                  <Breakpoint large up>
                    <div className="d-flex justify-content-end position-relative">
                      <Image alt="Arrow" src={ArrowUp} />
                    </div>
                  </Breakpoint>
                </div>
              </div>
              <div className="walsh h4" style={{ color: "#666666" }}>
                AVAILABLE NOW ON
                <Image alt="Oculus" className="px-2" src={meta}/>
              </div>
            </Breakpoint>
          </div>
        </Col>
        <Col lg={4}>
        </Col>
      </Row>
      {visible && <div
        className="d-flex justify-content-evenly align-items-center"
        style={{
          background: `url(${TextureSocialIcons})`,
          minHeight: "59px",
          minWidth: "192px",
          position: "fixed",
          bottom: "4%",
          right: "2%",
          zIndex: 100,
        }}
      >
        <a href={"https://www.facebook.com/darkdenvr/"} target={"_blank"}>
          <animated.img
            alt="Facebook"
            className="cursor-pointer"
            src={state1 ? facebookHover : facebook}
            onMouseEnter={() => {
              setState1(true);
            }}
            onMouseLeave={() => setState1(false)}
            style={state1 ? scaleIn : {}}
          />
        </a>
        <a href={"https://www.instagram.com/darkden.vr/"} target={"_blank"}>
          <animated.img
            alt="Instagram"
            className="cursor-pointer"
            src={state2 ? instagramHover : instagram}
            onMouseEnter={() => {
              setState2(true);
            }}
            onMouseLeave={() => setState2(false)}
            style={state2 ? scaleIn : {}}
          />
        </a>
        <a href={"https://www.youtube.com/channel/UCsrXFr6fC8F58zMu-4Rgx9g"} target={"_blank"}>
          <animated.img
            className="cursor-pointer"
            alt="Youtube"
            src={state3 ? youtubeHover : youtube}
            onMouseEnter={() => {
              setState3(true);
            }}
            onMouseLeave={() => setState3(false)}
            style={state3 ? scaleIn : {}}
          />
        </a>
        <a href={"https://discord.gg/sTUCXT3xMm"} target={"_blank"}>
          <animated.img
            className="cursor-pointer"
            alt="Discord"
            src={state4 ? discordHover : discord}
            onMouseEnter={() => {
              setState4(true);
            }}
            onMouseLeave={() => setState4(false)}
            style={state4 ? scaleIn : {}}
          />
        </a>
      </div>}
    </Container>
  );
};

const buttonStyle = {
  background: `url(${LandingPageButton}) no-repeat`,
  fontSize: "24px",
  border: "none",
  cursor: "pointer",
  lineHeight: "33.6px",
  minWidth: "233px",
  minHeight: "64px",
  zIndex: "2",
};

const buttonStyleMobile = {
  background: `url(${LandingPageButtonMobile}) no-repeat`,
  fontSize: "24px",
  border: "none",
  cursor: "pointer",
  lineHeight: "33.6px",
  letterSpacing: "10%",
  minWidth: "205px",
  minHeight: "49px",
  zIndex: "2",
};

export default Section1;
