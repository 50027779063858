import React, { useEffect, useState } from "react";
import {
    ErrorCharacter,
    ErrorPageButtonBg
} from "assets";
import { Col, Container, Image, Row, Button } from "react-bootstrap";
import { Breakpoint, useCurrentBreakpointName, useCurrentWidth } from "react-socks";
import NavigationBar from "components/NavigationBar";


const ErrorPage = () => {

    const width = useCurrentWidth();

    return (
        <Container className="p-0 w-100 error-page-background overflow-hidden position-relative user-select-none" fluid style={{ height: "100vh" }}>
            <NavigationBar />
            <Row className="px-lg-5">
                <Col xs={12} lg={6} className={`d-flex flex-column justify-content-center align-items-center align-items-lg-start ${width > 992 ? "vh-100" : "pt-5 mt-5 pb-4 mb-4"}`}>
                    <h1 className="h1 walsh text-maroon mt-5 my-lg-none">404</h1>
                    <h4 className="h4 walsh text-maroon">I THINK YOU ARE LOST SOMEWHERE!</h4>
                    <h4 className="h4 walsh text-maroon">FIND THE WAY TO FIGHT WITH ME</h4>
                    <div className="d-flex justify-content-start align-items-center">
                        <div className="d-flex">
                            <Button
                                href="/"
                                style={{
                                    background: `center / cover no-repeat url(${ErrorPageButtonBg})`,
                                    border: "none",
                                    cursor: "pointer",
                                    color: "#4D3A2D"
                                }}
                                className="walsh text-center px-5 py-3 h5"
                            >
                                BACK TO HOME
                            </Button>
                        </div>
                    </div>
                </Col>
                <Col xs={12} lg={6} className="d-flex justify-content-center position-relative">
                    <Image alt="Dark Den Error" src={ErrorCharacter} className="position-absolute" style={width > 992 ? {} :  {width: "150%"}} />
                </Col>
            </Row>
        </Container>
    );
};


export default ErrorPage;
