import React from "react";
import NavigationBar from "components/NavigationBar";
import { Container, Row, Col, Image } from "react-bootstrap";
import { AiOutlineLeft } from "react-icons/ai";
import { IoArrowBack } from "react-icons/io5";
import { Link } from "react-router-dom";
import { policy_lower_handle, policy_upper_handle, poster } from "assets";
import { useSpring, animated } from "react-spring";
import Footer from "./Footer";
import { Breakpoint, useCurrentWidth } from "react-socks";
import { Helmet } from "react-helmet";

const Credits = () => {

    const width = useCurrentWidth();
    const animationStyle = useSpring({
        from: { y: width < 992 ? -500 : -1200, transformOrigin: "top center" },
        to: { y: 0, transformOrigin: "top center" },
        // reset: true,
        delay: 2000,
        config: { duration: 2000 }
    });

    const fadeInStyle = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        delay: 4000,
        config: { duration: 1000 }
    });

    const scaleStyle = useSpring({
        from: { scale: 0.5, transformOrigin: "top center" },
        to: { scale: 1, transformOrigin: "top center" },
        delay: 1000,
        config: { duration: 1000 }
    });

    return (
        <React.Fragment>
            <Breakpoint large up>
                <Container className="user-select-none p-5 m-0 mt-4 policy_background" fluid style={styles.parentContainer}>
                    <NavigationBar />
                    <Container className="position-relative d-flex flex-column justify-content-center py-5">
                        <animated.div className="d-flex flex-column align-items-center" style={scaleStyle}>
                            <div style={{ zIndex: 40 }} className="position-absolute">
                                <Image alt="Credits" src={policy_upper_handle} />
                            </div>
                            <div className="overflow-hidden" style={{ marginTop: 130 }}>
                                <animated.div className="d-flex flex-column align-items-center" style={{ ...animationStyle, marginTop: -20 }}>
                                    <Image alt="Credits" src={poster} style={{ marginTop: 20, zIndex: 30 }} />
                                    <Image alt="Credits" src={policy_lower_handle} style={{ marginTop: 0, zIndex: 31 }} />
                                </animated.div>
                            </div>
                            <div className="position-absolute mx-5 px-5 py-4 mt-2" style={{ zIndex: 50 }}>
                                <div>
                                    <div className="my-4 d-flex align-items-center">
                                        <Col sm={2}>
                                            <Link to={-1} className="text-decoration-none d-flex justify-content-end align-items-center">
                                                <IoArrowBack className="walsh text-maroon h4" />
                                            </Link>
                                        </Col>
                                        <Col sm={8}>
                                            <h4 className="text-center walsh h4">CREDITS</h4>
                                        </Col>
                                    </div>
                                </div>
                                <Row>
                                    <Col as={animated.div} className="overflow-auto walsh text-uppercase" sm={{ span: 8, offset: 2 }} style={{ ...fadeInStyle }}>
                                        <Row className="mt-5">
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={4}
                                            >
                                                <h4 className="walsh h4">AUTHOR</h4>
                                            </Col>
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={8}
                                            >
                                                <h4 className="walsh h4">CREDIT</h4>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col as={animated.div} className="overflow-auto no-scroll-bar walsh text-uppercase" sm={{ span: 8, offset: 2 }} style={{ height: 1100, ...fadeInStyle }}>
                                        <Row className="mt-4">
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={4}
                                            >
                                                <div className="walsh">RunemarkStudio</div>
                                            </Col>
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={8}
                                            >
                                                <div className="walsh">
                                                    Spear Trap (wip) (https://skfb.ly/6XCZH) by RunemarkStudio is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={4}
                                            >
                                                <div className="walsh">dogheart</div>
                                            </Col>
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={8}
                                            >
                                                <div className="walsh">
                                                    This work is based on "Mutant Free low-poly 3D model" (https://www.cgtrader.com/free-3d-models/character/fantasy-character/mutant-5ed2b9de-daeb-4005-af3f-38875267be55) by dogheart (https://www.cgtrader.com/dogheart).
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={4}
                                            >
                                                <div className="walsh">Mmandali</div>
                                            </Col>
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={8}
                                            >
                                                <div className="walsh">
                                                    "Low poly stylized Torch" (https://skfb.ly/6TXPC) by MMandali is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={4}
                                            >
                                                <div className="walsh">Kigha</div>
                                            </Col>
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={8}
                                            >
                                                <div className="walsh">
                                                    "Grave Stone Collection" (https://skfb.ly/6SntP) by Kigha is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={4}
                                            >
                                                <div className="walsh">BhumiharBhai</div>
                                            </Col>
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={8}
                                            >
                                                <div className="walsh">
                                                    "God Of War Monster Free" () by BhumiharBhai is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={4}
                                            >
                                                <div className="walsh">Shedmon</div>
                                            </Col>
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={8}
                                            >
                                                <div className="walsh">
                                                    "Death Worm" (https://skfb.ly/o9QYK) by Shedmon is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={4}
                                            >
                                                <div className="walsh">NORBERTO-3D</div>
                                            </Col>
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={8}
                                            >
                                                <div className="walsh">
                                                    "GRIM REAPER" (https://skfb.ly/6zZWR) by NORBERTO-3D is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={4}
                                            >
                                                <div className="walsh">UROD Engine</div>
                                            </Col>
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={8}
                                            >
                                                <div className="walsh">
                                                    "Crying head" (https://skfb.ly/6vDJw) by UROD Engine is licensed under Creative Commons Attribution-ShareAlike (http://creativecommons.org/licenses/by-sa/4.0/).
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={4}
                                            >
                                                <div className="walsh">ChopperManiac</div>
                                            </Col>
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={8}
                                            >
                                                <div className="walsh">
                                                    "Flesh Blob" (https://skfb.ly/ooB6N) by ChopperManiac is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={4}
                                            >
                                                <div className="walsh">DivyeSh PanchAl</div>
                                            </Col>
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={8}
                                            >
                                                <div className="walsh">
                                                    "Hourglass" (https://skfb.ly/6TyzC) by DivyeSh PanchAl is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={4}
                                            >
                                                <div className="walsh">PantherOne</div>
                                            </Col>
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={8}
                                            >
                                                <div className="walsh">
                                                    "Rope Fence" (https://skfb.ly/FV6K) by PantherOne is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={4}
                                            >
                                                <div className="walsh">anavriN</div>
                                            </Col>
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={8}
                                            >
                                                <div className="walsh">
                                                    This work is based on "Lucy A Christian Angel Statue Free 3D model" (https://www.cgtrader.com/free-3d-models/character/fantasy-character/lucy-a-christian-angel-statue-bb54169c38f87b1130bf72bfa18b3d9c) by anavriN(https://www.cgtrader.com/anavrin)
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={4}
                                            >
                                                <div className="walsh">Siamak Tavakoli</div>
                                            </Col>
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={8}
                                            >
                                                <div className="walsh">
                                                    "Skull Gate" (https://skfb.ly/opyGZ) by Siamak Tavakoli is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={4}
                                            >
                                                <div className="walsh">Shedmon</div>
                                            </Col>
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={8}
                                            >
                                                <div className="walsh">
                                                    "Thing" (https://skfb.ly/6ANIn) by Shedmon is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={4}
                                            >
                                                <div className="walsh">Shedmon</div>
                                            </Col>
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={8}
                                            >
                                                <div className="walsh">
                                                    "Spider Thing" (https://skfb.ly/6YUX7) by Shedmon is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={4}
                                            >
                                                <div className="walsh">ssalasi</div>
                                            </Col>
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={8}
                                            >
                                                <div className="walsh">
                                                    "Bonfire" (https://skfb.ly/oyJoU) by ssalasi is licensed under Creative Commons Attribution (http://creativecommons.org/licenses/by/4.0/).
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={4}
                                            >
                                                <div className="walsh">Mixamo</div>
                                            </Col>
                                            <Col
                                                className="d-flex justify-content-start align-items-start p-0"
                                                xs={8}
                                            >
                                                <div className="walsh">
                                                    This work is based on "Mutant", "Warrok W Kurniawan", "Parasite L Starkie", "Zombiegirl W Kurniawan", "Elizabeth", "Bryce", "Brian", "yaku J ignite", "Megan" (https://www.mixamo.com/#/?page=1&type=Character).
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </animated.div>
                    </Container>
                </Container>
            </Breakpoint>
            <Breakpoint medium down>
                <Container className="user-select-none py-5 m-0 mt-4 policy_background" fluid style={styles.parentContainer}>
                    <NavigationBar />
                    <Container className="position-relative d-flex flex-column justify-content-center py-5 px-0 overflow-hidden">
                        <animated.div className="d-flex flex-column align-items-center" style={scaleStyle}>
                            <div style={{ zIndex: 40 }} className="position-absolute w-100 d-flex justify-content-center">
                                <Image alt="About Us" className="w-100" src={policy_upper_handle} style={{ minWidth: 400 }} />
                            </div>
                            <div className="overflow-hidden" style={{ marginTop: 40 }}>
                                <animated.div className="d-flex flex-column justify-content-center align-items-center" style={{ ...animationStyle, marginTop: -20 }}>
                                    <Image alt="About Us" className="w-75" src={poster} style={{ marginTop: 0, zIndex: 30 }} />
                                    <Image alt="About Us" className="w-100" src={policy_lower_handle} style={{ marginTop: -5, zIndex: 31 }} />
                                </animated.div>
                            </div>
                            <div className="position-absolute mx-5" style={{ zIndex: 50, marginTop: 10 }}>
                                <div>
                                    <Row>
                                        <Col xs={2} className="d-flex align-items-center justify-content-end">
                                            <Link to={-1} className="text-decoration-none d-flex justify-content-center align-items-center">
                                                <IoArrowBack className="walsh text-maroon mb-2 h4" />
                                            </Link>
                                        </Col>
                                        <Col xs={8} className="py-1">
                                            <h4 className="text-center walsh text-maroon">ABOUT US</h4>
                                        </Col>
                                    </Row>
                                </div>
                                <Row>
                                    <Col as={animated.div} className="overflow-auto walsh text-maroon text-uppercase paragraph" xs={{ span: 8, offset: 2 }} style={{ height: 325, ...fadeInStyle, fontSize: 10, lineHeight: 1.2 }}>
                                        <Row>
                                            <Col
                                                className="my-2"
                                                xs={12}
                                            >
                                                <p className="text-center text-justify text-lg-start">
                                                    Your will feel the adrenaline rush in your veins. You will focus and play the all-immersive Dark Den. If
                                                    you win, you will have a hit of dopamine and oxytocin. A sense of happiness and joy will take over.
                                                </p>
                                            </Col>
                                            <Col
                                                className="text-justify my-2"
                                                xs={12}
                                            >
                                                <div className="mt-3">
                                                    <p className="text-center text-justify text-lg-start">
                                                        Dark Den is an immersive role play based combat game available in Oculus Quest 2.
                                                    </p>
                                                </div>
                                                <div className="mt-3">
                                                    <p className="text-center text-justify text-lg-start">
                                                        This unique gameplay offers unparallel combat opportunities
                                                        along with the flavour of a boardgame. The immersive technology is best
                                                        experienced in virtual reality headsets like Oculus Quest 2.
                                                    </p>

                                                </div>
                                                <div className="mt-3">
                                                    <p className="text-center text-justify text-lg-start">
                                                        The parent organisation for Dark Den is Pastelcube
                                                        Technologies Private Limited and was founded with the aim to make use of
                                                        available technology to enrich lives.
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </animated.div>
                    </Container>
                </Container>
            </Breakpoint>
            <div style={{ marginTop: -120 }}>
                <Footer transparent={true} />
            </div>
        </React.Fragment>
    );
}

const styles = {
    parentContainer: {
        minHeight: "100vh",
        marginTop: "80px"
    }
};

export default Credits;