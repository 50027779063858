import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import Home from "pages/Home/Home";
import AboutUs from "pages/AboutUs";
import TermsOfUse from "pages/TermsOfUse";
import PrivacyPolicy from "pages/PrivacyPolicy";
import Credits from "pages/Credits";
import ScrollToTop from "components/ScrollToTop";
import Animation from "components/Animation";
import ErrorPage from "pages/Home/ErrorPage";

const Routers = () => {
  // console.log(window.pageYOffset);
  return (
    <Router>
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="about-us" element={<AboutUs />}></Route>
        <Route path="terms-of-use" element={<TermsOfUse />}></Route>
        <Route path="privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route path="credits" element={<Credits />}></Route>
        <Route path="*" element={<ErrorPage/>}></Route>
        {/* <Route path="animation" element={<Animation />}></Route> */}
      </Routes>
    </Router>
  );
};

export default Routers;
