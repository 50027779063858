import React, { useEffect, useState } from "react";
import NavigationBar from "components/NavigationBar";
import Section1 from "pages/Home/Section1";
import VideoSection from "pages/Home/VideoSection";
import Section2 from "pages/Home/Section2";
import Section3 from "pages/Home/Section3";
import Section4 from "pages/Home/Section4";
import Section5 from "pages/Home/Section5";
import Section6 from "pages/Home/Section6";
import Footer from "../Footer";
import { Container, Image } from "react-bootstrap";
import {
  EnvironmentVideo,
  // LandingPageVideo,
  LoadingDice
} from "assets";

import { AiOutlineClose } from "react-icons/ai";
import { animated, useSpring } from "react-spring";
import { Breakpoint } from "react-socks";
import { Helmet } from "react-helmet";

const Home = () => {
  const [modal, setModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [currentVideo, setCurrentVideo] = useState(LandingPageVideo);
  const [currentVideo, setCurrentVideo] = useState(null);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;

    if (scrolled > window.innerHeight) {
      setVisible(true);
    } else if (scrolled <= window.innerHeight) {
      setVisible(false);
    }
  };

  window.addEventListener("scroll", toggleVisible);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [])

  const fadeInStyle = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 2000,
    config: { duration: 1500 }
  });

  // const handleLoading = () => {
  //   setLoading(false);
  // }

  // useEffect(()=>{
  //   window.addEventListener("load",handleLoading);
  //   return () => window.removeEventListener("load",handleLoading);
  //   },[]
  // );    



  if (!loading) {
    return (
      <React.Fragment>
        {/* <Helmet>
          <title>Dark Den Game - Board & Dice Game | Best VR games oculus quest 2</title>
          <meta name="description" content="Dark Den - A dice game, a game of snakes and ladders, an action role play based game. The Dark Den Game is all set to be one of the best vr games of 2023" />
          <link rel="canonical" href="https://www.darkdengame.com/" />
          <link rel="alternate" href="https://www.darkdengame.com/" hreflang="en" />
        </Helmet> */}
        <Container as={animated.div} className="user-select-none p-0 m-0 position-relative" fluid style={{ ...fadeInStyle }}>
          {visible && <NavigationBar />}
          <Section1 watchTrailer={(visible) => {
            // setCurrentVideo(LandingPageVideo); 
            setModal(visible)
          }} />
          <VideoSection />
          <Section2 />
          <Section3 watchTrailer={(visible) => {
            // setCurrentVideo(EnvironmentVideo); 
            setModal(visible)
          }} />
          <Section4 />
          <Section6 />
          <Footer />
          {modal && <div className="w-100 position-fixed fixed-top" style={styles.modalContainer}>
            <Container className="position-relative h-100" fluid>
              <div className="position-absolute m-4 p-3" style={{ right: 0 }} onClick={() => setModal(false)} >
                <AiOutlineClose className="cursor-pointer" size={30} color="white" />
              </div>
              <div className="d-flex justify-content-center align-items-center h-100">
                <video
                  autoPlay="autoplay"
                  loop="loop"
                  playsInline=""
                  // poster={Section1Image}
                  className="landingPageVideo"
                  style={{
                    objectFit: "cover",
                    width: "75vw",
                    zIndex: 0
                  }}
                  controls
                >
                  <source src={"https://dukqh1e9lwqr5.cloudfront.net/EnvironmentVideo.mp4"} type="video/mp4" />
                </video>
              </div>
            </Container>
          </div>}
        </Container>
      </React.Fragment>
    );
  }

  if (loading) {
    return (
      <>
        <Container className="d-flex justify-content-center align-items-center" fluid style={styles.loadingContainer}>
          <Breakpoint large up>
            <video muted autoPlay loop>
              <source src={LoadingDice} type="video/webm" />
              Your browser does not support the video tag.
            </video>

          </Breakpoint>
          <Breakpoint medium down>
            <video width={200} muted autoPlay loop>
              <source src={LoadingDice} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </Breakpoint>
        </Container>
      </>
    );
  }
};

const styles = {
  modalContainer: {
    zIndex: 1000,
    backgroundColor: "rgba(50, 50, 50, 0.9)",
    top: 0,
    height: "100vh"
  },
  loadingContainer: {
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
  }
}

export default Home;
