import React, { useState, useEffect } from "react";
import {
  Section4Image,
  Section4ImageMobile,
  Enemy1,
  Enemy2,
  Enemy3,
  // Enemy4,
  OldEnemy1,
  OldEnemy2,
  OldEnemy3,
  OldEnemy4,
  Weapon2,
  Weapon3,
  Weapon4
} from "assets";
import { Row, Col, Container } from "react-bootstrap";
import { useSpring, animated, useTransition, config } from "react-spring";
import useWindowDimensions from "Utility/useWindowDimensions";
import { Breakpoint } from "react-socks";


const Section4 = () => {
  const { vw, vh } = useWindowDimensions();
  const [img1, setImg1] = useState(false);
  const [img2, setImg2] = useState(false);
  const [img3, setImg3] = useState(false);
  const [img4, setImg4] = useState(false);

  // const enemies = [{ key: 1, imgSrc: OldEnemy1 }, { key: 2, imgSrc: OldEnemy2 }, { key: 3, imgSrc: OldEnemy3 }, { key: 4, imgSrc: OldEnemy4 }];
  const enemies = [{ key: 1, imgSrc: Enemy1 }, { key: 2, imgSrc: Enemy2 }, { key: 3, imgSrc: Enemy3 }];
  const [enemy, setEnemy] = useState(0);
  const weapons = [{ key: 2, imgSrc: Weapon2 }, { key: 3, imgSrc: Weapon3 }, { key: 4, imgSrc: Weapon4 }];
  const [weapon, setWeapon] = useState(0);
  const scaleIn = useSpring({
    from: { transform: "scale(1)" },
    to: { transform: "scale(1.2)" },
    reset: true,
  });

  const [index, setIndex] = useState(0);

  const enemyTransition = useTransition(enemy, {
    from: {
      opacity: 0
    },
    enter: {
      opacity: 1
    },
    leave: {
      opacity: 0
    },
    config: { ...config.molasses },
  });
  const weaponTransition = useTransition(weapon, {
    from: {
      opacity: 0
    },
    enter: {
      opacity: 1
    },
    leave: {
      opacity: 0
    },
    config: { ...config.molasses },
  })


  useEffect(() => {
    const interval = setInterval(() => {
      setEnemy((old) => (old + 1) % enemies.length);
      setWeapon((old) => (old + 1) % weapons.length)
    }, 5000);

    return () => clearInterval(interval);
  }, []);


  return (
    <>
      <Container
        className="section3 justify-content-center align-items-center px-0 mx-0"
        style={
          vw >= 992
            ? {
              background: `top / cover no-repeat url(${Section4Image}) content-box`,
              marginTop: "-13%",
              height: "200vh",
              maxWidth: "100vw",
              position: "relative",
              zIndex: "4",
            }
            : {
              background: `top / cover no-repeat url(${Section4ImageMobile}) content-box`,
              marginTop: "-25%",
              height: "220vh",
              maxWidth: "100vw",
              position: "relative",
              zIndex: "4",
            }
        }
      >
        <Row
          style={vw >= 576 ? { minHeight: "100vh" } : { marginBottom: "5vh", minHeight: "100vh" }}
          className="align-items-center mx-0 px-2 px-lg-5"
        >
          <Col xs={12} lg={6} className="section4Text">
            <Breakpoint large up>
              <h4 className="walsh text-red-white-border h2 text-spacing-10">
                Be Scared to Die
              </h4>
            </Breakpoint>
            <Breakpoint medium down>
              <h4 className="walsh text-red-white-border mt-5 h2 text-spacing-10">
                Be Scared to Die
              </h4>
            </Breakpoint>
            <p className="walsh mt-4 me-lg-5 pe-lg-5 text-reddish-white paragraph">
              A gash of radiant light broke through the
              cauldron-black sky. Your axes were clattering and clanking into the enemy shields. As you wailed and screamed under the benzene-black sky, the theatre of death filled up with keening and
              caterwauling sounds as the sodden earth became oily with ichor. The ground was burnished with entrails and a sickly odour rose up from it. A spritz of blood spouted into the air. It tasted
              brackish. Come fight for your death!
            </p>
          </Col>
          <Col className="d-flex justify-content-center position-relative" xs={0} lg={6} style={{ zIndex: 2, paddingRight: "0px" }}>
            <Breakpoint large up>
              <div className="d-flex justify-content-center align-items-center">
                {enemyTransition((props, item) => {
                  return (
                    <animated.img alt="Enemies" className="position-absolute mx-4 mx-lg-auto" src={enemies[item].imgSrc} style={{ maxWidth: "70%", ...props }} loading="lazy"></animated.img>
                  );
                })}
              </div>
            </Breakpoint>
            <Breakpoint medium down>
              <div className="d-flex justify-content-center align-items-center">
                {enemyTransition((props, item) => {
                  return (
                    <animated.img alt="Enemies" className="position-absolute mx-4 mx-lg-auto" src={enemies[item].imgSrc} style={{ maxWidth: "100%", ...props }} loading="lazy"></animated.img>
                  );
                })}
              </div>
            </Breakpoint>
          </Col>
        </Row>
        <Row
          style={vw >= 576 ? { minHeight: "100vh" } : { marginBottom: "5vh", minHeight: "100vh" }}
          className="align-items-center px-2 px-lg-5 mx-0 flex-row-reverse"
        >
          <Col xs={12} lg={6}>
            <Breakpoint large up>
              <h5 className="walsh text-red-white-border text-start mt-5 mt-lg-0 h2 text-spacing-10">
                Fear pain
              </h5>
            </Breakpoint>
            <Breakpoint medium down>
              <h5 className="walsh text-red-white-border h2 text-spacing-10">
                Fear pain
              </h5>
            </Breakpoint>
            <p className="walsh mt-4 text-reddish-white paragraph">
              These shiny sharp objects break<br /> the unbreakable. Imagine what they<br /> can do to you; or your enemy.
            </p>
          </Col>
          <Col className="d-flex justify-content-center position-relative" xs={0} lg={6} style={{ zIndex: 2, paddingRight: "0px" }}>
            <div className="d-flex justify-content-center align-items-center">
              {weaponTransition((props, item) => {
                return (
                  <animated.img alt="Weapons" className="position-absolute mx-4 mx-lg-auto" src={weapons[item].imgSrc} style={{ maxWidth: "100%", ...props }}></animated.img>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Section4;
