import React, { useState, useEffect, useRef } from "react";
import { Section2Image, Section2Character_Bright, Section2ImageMobile } from "assets";
import { Row, Col, Container } from "react-bootstrap";
import useWindowDimensions from "Utility/useWindowDimensions";
import {
    // EnvironmentVideo,
    // LandingPageVideo,
    LoadingDice
} from "assets";

const VideoSection = () => {
    const { vw, vh } = useWindowDimensions();
    const videoRef = useRef();
    const [currentVideo, setCurrentVideo] = useState(null);

    useEffect(()=> {
        setTimeout(() => {
            // videoRef.current.muted = false;
            videoRef.current.play();
            // videoRef.current.click();
        }, 5000);
    }, []);

    return (
        <Container
            fluid
            className="d-flex justify-content-center align-items-center p-0 bg-black"
            style={{height: "100vh"}}
        >
            <video
                ref={videoRef}
                // autoPlay="autoplay"
                loop="loop"
                playsInline
                muted
                // poster={Section1Image}
                className="landingPageVideo w-100"
                style={{
                    objectFit: "cover",
                    height: vw > 1366 ? "100vh" : "auto",
                    zIndex: 0
                }}
                controls
            >
                <source src={"https://dukqh1e9lwqr5.cloudfront.net/LandingPageVideo.mp4"} type="video/mp4" />
            </video>
        </Container>
    );
};

export default VideoSection;
