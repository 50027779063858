import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
// import { Loader } from "components"
import { useState } from "react";

const ScrollToTop = () => {

    const [loading, setLoading] = useState(true);
    // document.body.style.overflow = "hidden"

    const location = useLocation();
    useEffect(() => {
        // console.log(location);
        setTimeout(() => {
            setLoading(false)
            // document.body.style.overflow = "auto"
        }, 600);
        window.scrollTo(0, 0);
        setLoading(true);
    }, [location?.pathname])

    if (loading) {
        return <div></div>
    }
    return <div/>;
}


export default ScrollToTop;