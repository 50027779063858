import React from "react";
import {
  Section3Image,
  Section3ImageMobile,
  Section34Image,
  Section2Character_Bright,
  Section3Left,
} from "assets";
import { Row, Col } from "react-bootstrap";
import useWindowDimensions from "Utility/useWindowDimensions";

const Section3 = ({ watchTrailer }) => {
  const { vw, vh } = useWindowDimensions();
  return (
    <>
      <Row
        className="section3 flex-lg-row-reverse justify-content-md-center align-items-center pb-lg-5"
        style={
          vw >= 992
            ? {
              background: `top / cover no-repeat url(${Section3Image}) content-box`,
              position: "relative",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "-5em",
              maxWidth: "100vw",
              minHeight: "110vh",
              zIndex: "2",
              // height: "90vw",
            }
            : {
              background: `top / cover no-repeat url(${Section3ImageMobile}) content-box`,
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "-7em",
              minHeight: "100vh",
              zIndex: "2",
            }
        }
      >
        <Col xs={12} lg={6} className="section3Text pb-lg-5 px-2 px-lg-5">
          <h3 className="walsh text-red-white-border color-red mt-5 mt-lg-0 text-spacing-10 h2">Be Afraid of the Dice</h3>
          <p className="walsh mt-4 mt-lg-0 mb-lg-5 pe-lg-5 text-reddish-white paragraph">
            You will have snakes, rhinos, and tigers to keep company. You will have arrows and spears to dodge. Fireballs, boulders will come your way and stalactites will drop on you.  Zipline and tight ropes will aid your journey across vicious peaks. Remember not to fall. And the Army of Belphegor shall be ready to snatch your soul away. Be warned!
          </p>
        </Col>
        <Col xs={12} lg={6} className="mb-5 pb-lg-5 d-flex justify-content-center px-2 px-lg-5" style={{ zIndex: 2 }}>
          <div>
            <img
              src={Section3Left}
              alt="intro video"
              className="pt-md-0 pt-lg-5 mb-lg-5"
              style={{ maxWidth: "100%", cursor: "pointer" }}
              onClick={() => {
                watchTrailer(true)
              }}
            />
          </div>
        </Col>
      </Row>
      {/* <img
        src={Section3Image}
        className="section3Image"
        style={{ position: "absolute", zIndex: 1, marginTop: "-75px" }}
      /> */}
    </>
  );
};

export default Section3;
