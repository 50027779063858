import React from "react";
import NavigationBar from "components/NavigationBar";
import { Container, Row, Col, Image } from "react-bootstrap";
import { AiOutlineLeft } from "react-icons/ai";
import { IoArrowBack } from "react-icons/io5";
import { Link } from "react-router-dom";
import { policy_lower_handle, policy_upper_handle, poster } from "assets";
import { useSpring, animated } from "react-spring";
import Footer from "./Footer";
import { Breakpoint, useCurrentWidth } from "react-socks";
import { Helmet } from "react-helmet";

const AboutUs = () => {

    const width = useCurrentWidth();
    const animationStyle = useSpring({
        from: { y: width < 992 ? -500 : -1200, transformOrigin: "top center" },
        to: { y: 0, transformOrigin: "top center" },
        // reset: true,
        delay: 2000,
        config: { duration: 2000 }
    });

    const fadeInStyle = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        delay: 4000,
        config: { duration: 1000 }
    });

    const scaleStyle = useSpring({
        from: { scale: 0.5, transformOrigin: "top center" },
        to: { scale: 1, transformOrigin: "top center" },
        delay: 1000,
        config: { duration: 1000 }
    });

    return (
        <React.Fragment>
            {/* <Helmet>
                <title>About Us | Dark Den Game - Snake & Ladder | Best VR game 2023</title>
                <meta name="description" content="Dark Den is a VR based game available in Oculus Quest 2. The game combines elements of snake & ladder, board game and combat rooled into a Virtual Reality game." />
                <link rel="canonical" href="https://www.darkdengame.com/about-us" />
                <link
                    rel="alternate"
                    href="https://www.darkdengame.com/about-us"
                    hreflang="en"
                />
            </Helmet> */}
            <Breakpoint large up>

                <Container className="user-select-none p-5 m-0 mt-4 policy_background" fluid style={styles.parentContainer}>
                    <NavigationBar />
                    <Container className="position-relative d-flex flex-column justify-content-center py-5">
                        <animated.div className="d-flex flex-column align-items-center" style={scaleStyle}>
                            <div style={{ zIndex: 40 }} className="position-absolute">
                                <Image alt="About Us" src={policy_upper_handle} />
                            </div>
                            <div className="overflow-hidden" style={{ marginTop: 130 }}>
                                <animated.div className="d-flex flex-column align-items-center" style={{ ...animationStyle, marginTop: -20 }}>
                                    <Image alt="About Us" src={poster} style={{ marginTop: 20, zIndex: 30 }} />
                                    <Image alt="About Us" src={policy_lower_handle} style={{ marginTop: 0, zIndex: 31 }} />
                                </animated.div>
                            </div>
                            <div className="position-absolute mx-5 px-5 py-4 mt-2" style={{ zIndex: 50 }}>
                                <div>
                                    <div className="my-4 d-flex align-items-center">
                                        <Col sm={2}>
                                            <Link to={-1} className="text-decoration-none d-flex justify-content-end align-items-center">
                                                <IoArrowBack className="walsh text-maroon h4" />
                                            </Link>
                                        </Col>
                                        <Col sm={8}>
                                            <h4 className="text-center walsh h4">ABOUT US</h4>
                                        </Col>
                                    </div>
                                </div>
                                <Row>
                                    <Col as={animated.div} className="overflow-auto walsh text-uppercase" sm={{ span: 8, offset: 2 }} style={{ height: 1300, ...fadeInStyle }}>
                                        <Row>
                                            <Col
                                                className="my-2"
                                                style={styles.privacyPolicyDescription}
                                                xs={12}
                                            >
                                                <p className="text-center text-justify text-lg-start pt-5">
                                                    Your will feel the adrenaline rush in your veins. You will focus and play the all-immersive Dark Den. If
                                                    you win, you will have a hit of dopamine and oxytocin. A sense of happiness and joy will take over.
                                                </p>
                                            </Col>
                                            <Col
                                                className="text-justify my-2"
                                                style={styles.privacyPolicyDescription}
                                                xs={12}
                                            >
                                                <div className="mt-3">
                                                    <p className="text-center text-justify text-lg-start">
                                                        Dark Den is an immersive role play based combat game available in Oculus Quest 2.
                                                    </p>
                                                </div>
                                                <div className="mt-3">
                                                    <p className="text-center text-justify text-lg-start">
                                                        This unique gameplay offers unparallel combat opportunities
                                                        along with the flavour of a boardgame. The immersive technology is best
                                                        experienced in virtual reality headsets like Oculus Quest 2.
                                                    </p>

                                                </div>
                                                <div className="mt-3">
                                                    <p className="text-center text-justify text-lg-start">
                                                        The parent organisation for Dark Den is Pastelcube
                                                        Technologies Private Limited and was founded with the aim to make use of
                                                        available technology to enrich lives.
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </animated.div>
                    </Container>
                </Container>
            </Breakpoint>
            <Breakpoint medium down>
                <Container className="user-select-none py-5 m-0 mt-4 policy_background" fluid style={styles.parentContainer}>
                    <NavigationBar />
                    <Container className="position-relative d-flex flex-column justify-content-center py-5 px-0 overflow-hidden">
                        <animated.div className="d-flex flex-column align-items-center" style={scaleStyle}>
                            <div style={{ zIndex: 40 }} className="position-absolute w-100 d-flex justify-content-center">
                                <Image alt="About Us" className="w-100" src={policy_upper_handle} style={{ minWidth: 400 }} />
                            </div>
                            <div className="overflow-hidden" style={{ marginTop: 40 }}>
                                <animated.div className="d-flex flex-column justify-content-center align-items-center" style={{ ...animationStyle, marginTop: -20 }}>
                                    <Image alt="About Us" className="w-75" src={poster} style={{ marginTop: 0, zIndex: 30 }} />
                                    <Image alt="About Us" className="w-100" src={policy_lower_handle} style={{ marginTop: -5, zIndex: 31 }} />
                                </animated.div>
                            </div>
                            <div className="position-absolute mx-5" style={{ zIndex: 50, marginTop: 10 }}>
                                <div>
                                    <Row>
                                        <Col xs={2} className="d-flex align-items-center justify-content-end">
                                            <Link to={-1} className="text-decoration-none d-flex justify-content-center align-items-center">
                                                <IoArrowBack className="walsh text-maroon mb-2 h4" />
                                            </Link>
                                        </Col>
                                        <Col xs={8} className="py-1">
                                            <h4 className="text-center walsh text-maroon">ABOUT US</h4>
                                        </Col>
                                    </Row>
                                </div>
                                <Row>
                                    <Col as={animated.div} className="overflow-auto walsh text-maroon text-uppercase paragraph" xs={{ span: 8, offset: 2 }} style={{ height: 325, ...fadeInStyle, fontSize: 10, lineHeight: 1.2 }}>
                                        <Row>
                                            <Col
                                                className="my-2"
                                                style={styles.privacyPolicyDescription}
                                                xs={12}
                                            >
                                                <p className="text-center text-justify text-lg-start">
                                                    Your will feel the adrenaline rush in your veins. You will focus and play the all-immersive Dark Den. If
                                                    you win, you will have a hit of dopamine and oxytocin. A sense of happiness and joy will take over.
                                                </p>
                                            </Col>
                                            <Col
                                                className="text-justify my-2"
                                                style={styles.privacyPolicyDescription}
                                                xs={12}
                                            >
                                                <div className="mt-3">
                                                    <p className="text-center text-justify text-lg-start">
                                                        Dark Den is an immersive role play based combat game available in Oculus Quest 2.
                                                    </p>
                                                </div>
                                                <div className="mt-3">
                                                    <p className="text-center text-justify text-lg-start">
                                                        This unique gameplay offers unparallel combat opportunities
                                                        along with the flavour of a boardgame. The immersive technology is best
                                                        experienced in virtual reality headsets like Oculus Quest 2.
                                                    </p>

                                                </div>
                                                <div className="mt-3">
                                                    <p className="text-center text-justify text-lg-start">
                                                        The parent organisation for Dark Den is Pastelcube
                                                        Technologies Private Limited and was founded with the aim to make use of
                                                        available technology to enrich lives.
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </animated.div>
                    </Container>
                </Container>
            </Breakpoint>
            <div style={{ marginTop: -120 }}>
                <Footer transparent={true} />
            </div>
        </React.Fragment>
    );
}

const styles = {
    parentContainer: {
        minHeight: "100vh",
        marginTop: "80px"
    }
};

export default AboutUs;