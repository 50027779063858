import React from "react";
import { Nav, Navbar, Button, Row, Col, Image } from "react-bootstrap";
import { DarkDenLogo, NavBarButton } from "assets";
import { Link } from "react-router-dom";
import { Breakpoint, useCurrentWidth } from "react-socks";

const NavigationBar = () => {
  const width = useCurrentWidth()

  return (
    <Navbar
      id="navbar"
      variant="dark"
      className="position-fixed fixed-top align-items-center justify-content-center p-2 m-0"
      style={{ zIndex: 100 }}
    >
      {/* <div style={{ minWidth: "20%" }} className="d-none d-md-block"></div> */}
      <Row className="w-100 p-0 mx-4">
        <Col sm={6} className={`${width > 768 ? "" : "text-center"}`}>
          <Breakpoint large up>
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              <div>
                <img
                  src={DarkDenLogo}
                  className="d-inline-block align-top"
                  alt="Dark Den Game"
                />
              </div>
            </Link>
          </Breakpoint>
          <Breakpoint medium down>
            <Link to="/">
              <div>
                <img
                  src={DarkDenLogo}
                  className="d-inline-block align-top"
                  alt="Dark Den Game"
                  style={{ width: 116 }}
                />
              </div>
            </Link>
          </Breakpoint>
        </Col>
        {/* <div style={{ marginLeft: "auto", marginRight: "auto" }}>
        <img src={DarkDenLogo} className="navbarLogo" />
      </div> */}
        <Col sm={6} className="d-flex justify-content-end align-items-center p-0 m-0">
          <Button
            style={{
              background: `url(${NavBarButton}) no-repeat`,
              minWidth: "206px",
              minHeight: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "24px",
              border: "none",
              cursor: "pointer",
              color: "#4D3A2D"
            }}
            className="walsh text-center d-none d-lg-flex me-4"
            target="_blank"
            href="https://www.znanye.com/#byvrheadset"
          >
            {/* <Image className="position-absolute" src={NavBarButton}/>
            <div className="position-absolute d-flex justify-content-center align-items-center"> */}
            Buy VR Headset
            {/* </div> */}
          </Button>
          <Button
            style={{
              background: `url(${NavBarButton}) no-repeat`,
              minWidth: "206px",
              minHeight: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "24px",
              border: "none",
              cursor: "pointer",
              color: "#4D3A2D"
            }}
            className="walsh text-center d-none d-lg-flex"
            href="https://www.oculus.com/experiences/quest/5116975614988301/?utm_source=Website&utm_medium=Buy_Now"
            target="__blank"
          >
            {/* <Image className="position-absolute" src={NavBarButton}/>
            <div className="position-absolute d-flex justify-content-center align-items-center"> */}
            Buy Game
            {/* </div> */}
          </Button>
        </Col>
      </Row>
    </Navbar>
  );
};

export default NavigationBar;
