import React, { useState } from "react";
import {
  FooterBgCropped,
  FooterBgMobile,
  facebookFooter,
  instagramFooter,
  twitterFooter,
  youtubeFooter,
  discordFooter,
  facebookFooterHover,
  instagramFooterHover,
  twitterFooterHover,
  youtubeFooterHover,
  discordFooterHover,
  map,
  mail,
  call,
  end,
  madeInIndia,
} from "../assets";
import { Row, Col, Image, Container } from "react-bootstrap";
import useWindowDimensions from "../Utility/useWindowDimensions";
import { useSpring, animated } from "react-spring";
import { Link } from "react-router-dom";
import { Breakpoint } from "react-socks";

const Footer = ({ transparent }) => {
  const { vw, vh } = useWindowDimensions();
  const [state1, setState1] = useState(false);
  const [state2, setState2] = useState(false);
  const [state3, setState3] = useState(false);
  const [state4, setState4] = useState(false);
  const [state5, setState5] = useState(false);

  const animation = useSpring({
    from: { transform: "scale(1)" },
    to: { transform: "scale(1.05)" },
    reset: true,
  });

  return (
    <>
      <Container
        fluid
        className="d-flex align-items-end align-items-lg-center text-center px-2 px-lg-5"
        // style={
        //   vw >= 576
        //     ? {
        //       background: `top / cover no-repeat url(${FooterBgCropped}) border-box, ${transparent ? "transparent" : "black"}`,
        //       marginTop: "-5%",
        //       minHeight: "100vh",
        //       position: "relative",
        //       zIndex: "8",
        //       width: "100vw",
        //       marginRight: "0px",
        //       marginLeft: "0px",
        //     }
        //     : {
        //       background: `top / cover no-repeat url(${FooterBgMobile}) border-box, ${transparent ? "transparent" : "black"}`,
        //       marginTop: "-5%",
        //       minHeight: "100vh",
        //       position: "relative",
        //       zIndex: "8",
        //       width: "100vw",
        //       paddingTop: "15vh",
        //     }
        // }
        style={{
          marginTop: "-5%",
          background: `top / cover no-repeat url(${FooterBgCropped}) border-box, ${transparent ? "transparent" : "black"}`,
          minHeight: "100vh",
          position: "relative",
          zIndex: "8",
          width: "100vw",
          marginRight: "0px",
          marginLeft: "0px",
        }}
      >
        <div className="w-100">

          <Row className="px-lg-5 px-2" style={vw >= 576 ? { paddingTop: "10%" } : {}}>
            <Col xs={12} lg={5}>
              <h4 className="walsh text-start h4 text-maroon">
                LETS STAY CONNECTED
              </h4>
              <div className="d-flex justify-content-start">
                <a href={"https://www.facebook.com/darkdenvr/"} target={"_blank"}>
                  <animated.img
                    className="cursor-pointer"
                    src={state1 ? facebookFooterHover : facebookFooter}
                    alt="facebook"
                    height={vw >= 576 ? 54 : 36}
                    style={state1 ? animation : {}}
                    onMouseEnter={() => setState1(true)}
                    onMouseLeave={() => setState1(false)}
                  />
                </a>
                <a href={"https://www.instagram.com/darkden.vr/"} target={"_blank"}>
                  <animated.img
                    src={state2 ? instagramFooterHover : instagramFooter}
                    alt="instagram"
                    height={vw >= 576 ? 54 : 36}
                    style={state2 ? animation : {}}
                    onMouseEnter={() => setState2(true)}
                    onMouseLeave={() => setState2(false)}
                    className="ps-4 cursor-pointer"
                  />
                </a>
                <a href={"https://twitter.com/DarkDengame"} target={"_blank"}>
                  <animated.img
                    src={state3 ? twitterFooterHover : twitterFooter}
                    alt="twitter"
                    height={vw >= 576 ? 54 : 36}
                    style={state3 ? animation : {}}
                    onMouseEnter={() => setState3(true)}
                    onMouseLeave={() => setState3(false)}
                    className="ps-4 cursor-pointer"
                  />
                </a>
                <a href={"https://www.youtube.com/channel/UCsrXFr6fC8F58zMu-4Rgx9g"} target={"_blank"}>
                  <animated.img
                    src={state4 ? youtubeFooterHover : youtubeFooter}
                    alt="youtube"
                    height={vw >= 576 ? 54 : 36}
                    style={state4 ? animation : {}}
                    onMouseEnter={() => setState4(true)}
                    onMouseLeave={() => setState4(false)}
                    className="ps-4 cursor-pointer"
                  />
                </a>
                <a href={"https://discord.gg/sTUCXT3xMm"} target={"_blank"}>
                  <animated.img
                    src={state5 ? discordFooterHover : discordFooter}
                    alt="discord"
                    height={vw >= 576 ? 54 : 36}
                    style={state5 ? animation : {}}
                    onMouseEnter={() => setState5(true)}
                    onMouseLeave={() => setState5(false)}
                    className="ps-4 cursor-pointer"
                  />
                </a>
              </div>
            </Col>
            <Col xs={12} lg={3} className="ps-lg-5 mt-4 mt-lg-2">
              <h4 className="walsh text-start h4 text-maroon pb-2">COMPANY INFO</h4>
              <a href="https://www.business-standard.com/content/press-releases-ani/get-ready-to-enter-a-new-world-of-gaming-with-the-launch-of-dark-den-123022800904_1.html" target={"_blank"}>
                <h5 className="walsh text-start h5 text-maroon mt-2 mt-xl-4">We are in news!</h5>
              </a>
              <Link to="/about-us">
                <h5 className="walsh text-start h5 text-maroon mt-2 mt-xl-4">About Us</h5>
              </Link>
              <Link to="/terms-of-use">
                <h5 className="walsh text-start h5 text-maroon mt-2 mt-xl-4">Terms Of Use</h5>
              </Link>
              <Link to="/privacy-policy">
                <h5 className="walsh text-start h5 text-maroon mt-2 mt-xl-4">
                  Privacy Policy
                </h5>
              </Link>
              <a href="https://www.znanye.com/" target={"__blank"}>
                <h5 className="walsh text-start h5 text-maroon mt-2 mt-xl-4">Znanye</h5>
              </a>
              <Link to="/credits">
                <h5 className="walsh text-start h5 text-maroon mt-2 mt-xl-4">
                  Credits
                </h5>
              </Link>
            </Col>
            <Col xs={12} lg={4} className="text-start mt-4 mt-lg-2">
              <h4 className="walsh text-start h4 text-maroon pb-2">CONTACT DETAILS</h4>
              <h5 className="walsh text-start text-maroon h5 mt-4">
                <img alt="Location" src={map} className="pe-3" height={20} />
                Park Street, Pune - 411 057
              </h5>
              <div className="mt-4">
                <a
                  href="mailto:support@darkdengame.com"
                  className="walsh text-start text-maroon text-decoration-none h5"
                >
                  <img alt="Mail" src={mail} className="pe-3" height={20} />
                  support@darkdengame.com
                </a>
              </div>
              {/* <div className="my-2">
              <a
                href="tel:18002664020"
                className="walsh text-left text-decoration-none"
              >
                <img src={call} className="pe-3" height={20} />
                1800 266 4020
              </a>
            </div> */}
            </Col>
          </Row>
          <Row
            style={{
              minHeight: "25vh",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              paddingBottom: "2em",
            }}
            className="ps-3 ps-md-0 mt-5 mt-lg-4"
          >
            <Image className="position-absolute d-none d-lg-block" style={{ width: "95%", zIndex: -1 }} src={end} />
            <Col
              xs={12}
              lg={4}
            >
              <div className="walsh text-black text-start text-lg-end body-text1">
                CIN : U72900PN2020PTC193934
              </div>
            </Col>
            <Col
              xs={12}
              lg={5}
            >
              <a href="https://www.pastelcube.com/" target={"__blank"}>
                <div className="walsh text-black body-text1 text-start text-lg-center">
                  Pastelcube Technologies Private Limited
                </div>
              </a>
            </Col>
            <Col xs={12} lg={3} className="d-flex justify-content-start">
              <div className="walsh text-black body-text1 text-start">
                Made in India with{" "}
                <Image className="Made in India" src={madeInIndia} />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Footer;
